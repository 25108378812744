<template>
  <section>
    <terminos-condiciones></terminos-condiciones>
  </section>
</template>

<script>
import TerminosCondiciones from '@/components/TerminosCondiciones.vue'
export default {
  name: 'TerminosCondicionesPage',
  components: { TerminosCondiciones },
}
</script>
