<template>
  <nav id="nav" class="navbar navbar-expand-xl navbar-light fixed-top">
    <div class="container">
      <router-link :to="{ name: 'LandingPage' }">
        <img
          id="nav-logo"
          src="@/assets/img/QRM-03.png"
          alt="Logo Quiero Registrar Mi Marca"
        />
      </router-link>
      <a class="navbar-brand" href="#"></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarLinks"
        aria-controls="navbarLinks"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        id="navbarLinks"
        class="collapse navbar-collapse justify-content-end"
      >
        <ul class="navbar-nav">
          <li
            class="nav-item"
            v-for="opcion in opcionesMenu"
            :key="opcion.texto"
          >
            <router-link
              class="nav-link"
              :to="'/' + opcion.href"
              v-scroll-to="opcion.href"
              >{{ opcion.texto.toUpperCase() }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
const $ = require('jquery');
import {contadorUsuarios} from "../assets/js/ContadorUsuarios.js";
export default {
  name: 'MainMenu',
  props: ['opcionesMenu'],
  mounted(){
    this.capturarDatosUsuario();
    this.eventSave();
  },
  methods:{
    capturarDatosUsuario(){
         let apiKey = 'be0f755b93290b4c100445d77533d291763a417c75524e95e07819ad';
         $.getJSON('https://api.ipdata.co?api-key=' + apiKey, function(data) {
          var ipUsuario =data['ip'];
          var region=data['region'];
          var fecha=data['time_zone']['current_time'];
          var pais= data['country_name'];
          var seccion="HOME";
          contadorUsuarios.datosUsuario(ipUsuario,region,fecha,pais,seccion,0);
         });
    },
    eventSave(){
    var ask = true;
    window.onbeforeunload = function(e) {
       if (!ask) return null
       var subtotal = $('#lblgtwithsgst').text();
         if (subtotal != '0') {
          e = e || window.event;
            if (e) {
             //e.returnValue = 'Sure?';
            }
            contadorUsuarios.enviarDatos();
         }  
      }
      
    }
  }
}

</script>

