<template>
  <div>Aqui van confirmación</div>
</template>

<script>
export default {
  name: 'SolicitudPage',
}
</script>

<style scoped>
div {
  margin-top: 500px;
}
</style>
