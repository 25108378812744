<template>
  <div id="contacto">
    <div class="container">
      <h2 class="subtitle">CONTACTO</h2>
      <hr class="divider" />
      <div class="row align-items-center">
        <div id="foto-contacto" class="col-md-6 mb-4 text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.148359651971!2d-99.16582478509368!3d19.405994786899406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff4fd451eb65%3A0xd66b76f5b9a7917a!2sQRM%20Quiero%20Registrar%20Mi%20Marca!5e0!3m2!1ses-419!2smx!4v1634153440482!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy">"
            width="100%"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <div class="col-md-6 mb-4" style="padding-left:60px">
          <div class="row" style="padding-bottom:20px;">
           <img src="../assets/img/QRMLogo.png" alt="Logo QRM" style="width:250px" class="mx-auto" />
          </div>
          <form name="contact" id="app" method="keywords" ovalidate="true">
            <div class="row form-group">
              <div class="col-md-6 mb-3 mb-md-0">
                <b-form-input
                  v-model="nombre"
                  type="text"
                  placeholder="Nombre Completo"
                  trim
                  v-on:keypress="isLetter($event)"
                >
                </b-form-input>
              </div>
              <div class="col-md-6">
                <b-form-input
                  v-model="telefono"
                  type="tel"
                  placeholder="Teléfono"
                  trim
                  v-on:keypress="isNumber($event)"
                >
                </b-form-input>
              </div>
            </div>
            <div class="form-group">
              <b-form-input
                v-model="email"
                type="email"
                placeholder="Correo electrónico"
                trim
              >
              </b-form-input>
            </div>
            <div class="form-group">
              <b-form-textarea
                v-model="mensaje"
                placeholder="Mensaje"
                v-on:keypress="isLetter($event)"
                rows="3"
              ></b-form-textarea>
            </div>
            <div v-if="errors.length > 0">
              <b-alert show variant="danger">
                <p v-for="(error, i) in errors" :key="i" class="my-0">
                  {{ error }}
                </p>
              </b-alert>
            </div>
            <button
              id="btn-contacto"
              class="btn"
              type="button"
              @click="nuevoContacto"
            >
              Enviar Mensaje
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contacto',
  data() {
    return {
      nombre: '',
      telefono: '',
      email: '',
      correo: false,
      mensaje: '',
      errors: [],
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode)
      const regex = /^[A-Za-z\u00C0-\u024F\u1E00-\u1EFF\s,;.]+$/
      if (regex.test(char)) return true
      else e.preventDefault()
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(char)) return true
      else e.preventDefault()
    },
    validEmail: function (correo) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(correo)
    },
    checkForm() {
      this.errors = []

      if (!this.nombre) {
        this.errors.push('El campo de nombre es requerido')
      }

      if (this.telefono == '') {
        this.errors.push('El campo de teléfono es requerido')
      }

      if (this.telefono.length != 10) {
        this.errors.push('Ingrese un número de teléfono válido')
      }

      if (this.email == '') {
        this.errors.push('El campo de correo electrónico es requerido')
      }

      if (!this.validEmail(this.email)) {
        this.errors.push('Ingrese un correo electrónico válido')
      }

      if (this.mensaje == '') {
        this.errors.push('El campo de mensaje es requerido')
      }

      return true
    },
    nuevoContacto() {
      this.checkForm()

      if (this.errors.length > 0) return false
      const date = new Date()
      // TODO: usar interpolación
      const fecha_hora = date.getFullYear() + "-" + date.getDate()  + "-" + (date.getMonth()+1) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
      let data = {
        id_marca: "16",
        id_bandeja: "1",
        nombre_completo: this.nombre,
        telefono: this.telefono,
        correo: this.email,
        mensaje: this.mensaje,
        mas_info: '',
        fecha_hora: fecha_hora,
        id_status: '1'
      }

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }

      const url = `https://www.quieroregistrarmimarca.com/HerCarContacto/api/api_mensajes.php`

      try {
        this.axios.post(url, data, axiosConfig).then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: 'success',
              title: 'Mensaje enviado',
              showConfirmButton: false,
              timer: 3000,
            })
            this.nombre = ''
            this.telefono = ''
            this.email = ''
            this.mensaje = ''
          } else {
            this.$swal({
              icon: 'error',
              title: 'No se pudo enviar el mensaje, intente mas tarde',
              showConfirmButton: false,
              timer: 3000,
            })
          }
        })
      } catch (e) {
        this.$swal({
          icon: 'error',
          title: 'No se pudo enviar el mensaje, intente mas tarde',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    },
  },
}
</script>
