<template>
  <div id="aviso-privacidad" class="container text-justify"  style="margin-top: 150px;">
    <h2 class="text-center">Aviso de Privacidad</h2>
    <hr class="divider text-center" />
    <!--<img class="logo-modal" src="img/privacidad/2000_5e33d0b98abba.png" alt="" /> -->
    <p>
      Hernández Carreño Abogados(en adelante “Hernández Carreño”), con domicilio
      en Avenida Baja California 146, Despacho 301, Colonia Roma Sur, Delegación
      Cuauhtémoc, Código Postal 06770, Ciudad de México, es responsable del
      tratamiento de los datos personales que nos proporcione, mismos que serán
      protegidos por la Ley Federal de Protección de Datos Personales en
      Posesión de los Particulares(en adelante la “Ley”) así como la normativa
      que sea aplicable, con base en los principios de licitud, calidad,
      consentimiento, información, finalidad, lealtad, proporcionalidad y
      responsabilidad, por lo que la información de nuestros clientes y clientes
      potenciales es tratada de forma estrictamente confidencial.
    </p>

    <ol type="I">
      <li>
        <b>Datos personales que serán sometidos a tratamiento</b>
        <ol type="A">
          <li>
            <b>Datos de identificación:</b> Nombre completo, domicilio
            particular y del establecimiento.
          </li>
          <li>
            <b>Datos de contacto:</b> Correo electrónico, teléfono fijo,
            teléfono celular.
          </li>
          <li>
            <b>Datos de contacto:</b> datos para denominación y logotipo de la
            marca a registrar, fecha de inicio de uso, aviso comercial (slogan),
            indicación de productos o servicios que ofrece.
          </li>
        </ol>
      </li>

      <li>
        <b>Finalidades necesarias del tratamiento</b>
        <ol type="i">
          <li>
            Clientes potenciales
            <ol type="a">
              <li>Verificar su identidad y registro en base de datos</li>
              <li>Cotización del producto o servicio de interés</li>
            </ol>
          </li>
          <li>
            Clientes
            <ol type="a">
              <li>Tramitar operaciones en materia de Propiedad Industrial</li>
              <li>Proveer los servicios que se han solicitado</li>
              <li>
                Notificar sobre nuevos servicios o productos que tengan relación
                con los ya adquiridos
              </li>
              <li>Comunicar sobre cambios en los productos</li>
              <li>
                Complementar la información para la entrega de facturas,
                notificaciones, avisos
              </li>
              <li>
                Para dar cumplimiento a las obligaciones que hemos contraído con
                usted
              </li>
            </ol>
          </li>
        </ol>
      </li>

      <li>
        <b>Finalidades no necesarias del tratamiento</b>
        <ol type="i">
          <li>Dar publicidad a nuestros servicios mediante “casos de éxito”</li>
          <li>
            Realizar publicaciones de títulos de marcas otorgadas por medio de
            redes sociales y en nuestro muro de marcas en la oficina física
          </li>
          <li>Evaluar la calidad del servicio que brindamos</li>
        </ol>
      </li>

      <li>
        <b
          >Negativa para el tratamiento de sus datos personales para aquellas
          finalidades que no son necesarias
        </b>
        <p>
          Si desea manifestar su negativa para el tratamiento de sus datos
          personales para las finalidades que no son necesarias para la relación
          jurídica objeto del presente, al correo electrónico
          contacto@hernadezcarreno.com, a los teléfonos 5219 8599 y 8852 7529, o
          directamente en el domicilio indicado anteriormente.
        </p>
      </li>

      <li>
        <b>Transferencia de datos personales</b>
        <p>
          En el caso de que los datos personales se vayan a proporcionar a
          socios comerciales por ejemplo, se debe especificar datos de los
          mismos y las finalidades.
        </p>
        <ol type="a">
          <li>
            Nos comprometemos a no transferir su información personal a
            terceros, salvo las excepciones previstas en la “Ley”.
          </li>
        </ol>
      </li>

      <li>
        <b
          >De los derechos de acceso, rectificación, cancelación u oposición
          (ARCO) y revocación del consentimiento al tratamiento de sus datos
          personales</b
        >
        <p>
          Usted (en adelante “el titular”) tendrá derecho en todo momento a:
        </p>
        <ol type="a">
          <li>
            Acceder a sus datos personales que posee “Hernández Carreño” y a los
            detalles del tratamiento de estos;
          </li>
          <li>Rectificarlos en caso de ser inexactos o incompletos;</li>
          <li>
            Cancelarlos cuando considere que no se requieren para alguna de las
            finalidades señalados en el presente aviso de privacidad, porque
            estén siendo utilizados para finalidades no consentidas o haya
            finalizado la relación contractual del servicio;
          </li>
          <li>Oponerse al tratamiento de los mismos para fines específicos;</li>
          <li>
            Revocar el consentimiento que ha otorgado para el tratamiento de sus
            datos personales, a fin de que “Hernández Carreño” deje de hacer uso
            de estos.
          </li>
        </ol>

        <p>
          Para el ejercicio de los mencionados derechos el titular o su
          representante legal podrán solicitar un formato de ejercicio de
          Derechos ARCO (en adelante “el formato”) a la dirección de correo
          electrónico contacto@hernandezcarreño.com, dicho formato se deberá ser
          llenado, firmado y devuelto a la misma dirección de correo electrónico
          acompañado de la documentación que más adelante se indica, misma que
          deberá enviarse para su evaluación digitalizada y legible, con objeto
          de que el responsable pueda llevar a cabo la autenticación del titular
          que requiera ejercer sus Derechos ARCO.
        </p>

        <p>La solicitud deberá contener al menos la siguiente información:</p>
        <ol type="a">
          <li>
            Nombre completo del titular de los datos y correo electrónico o
            domicilio para comunicarle la respuesta a su solicitud.
          </li>
          <li>
            Los documentos oficiales que acrediten la identidad del titular o,
            en su caso, de la representación legal (Credencial de Elector
            vigente otorgada por el Instituto Nacional Electoral “INE” o
            Pasaporte vigente). En caso de que el titular ejerza sus derechos
            ARCO a través de un representante legal, además de acreditar la
            identidad de ambos (titular y representante legal), se deberá enviar
            a la anteriormente citada dirección de correo, una copia legible del
            poder notarial otorgado al representante legal, o en su caso carta
            poder firmada ante dos testigos o declaración en comparecencia del
            titular por el cual se otorgan facultades para llevar a cabo este
            trámite ante el responsable.
          </li>
          <li>
            La descripción clara y precisa de los datos personales respecto de
            los que busca ejercer alguno de los derechos ARCO.
          </li>
          <li>
            Cualquier otro elemento o documento que facilite la localización de
            los datos personales.
          </li>
        </ol>

        <p>
          Después de recibida la solicitud mediante el formato debidamente
          requisitado:
        </p>

        <p>
          El responsable comunicará la resolución al titular en un plazo máximo
          de 20 (veinte) días hábiles contados a partir de la fecha de recepción
          del formato por medio de la dirección de correo electrónico
          contacto@hernandezcarreño.com, la respuesta a su solicitud de
          ejercicio de derechos ARCO.
        </p>

        <p>
          Si la resolución del responsable resulta a favor del titular, esta se
          hará efectiva dentro de los 15 (quince) días hábiles siguientes a
          partir de la comunicación de la resolución. El responsable podrá
          ampliar este plazo hasta por otros 15 (quince) días hábiles, cuando
          sea necesario de acuerdo con la situación, previa notificación de
          dicha situación al titular.
        </p>

        <p>
          En el caso de que el titular tenga el deseo de revocar el
          consentimiento al tratamiento de sus datos personales, aplicará el
          mismo procedimiento, requisitos, plazos y medios de respuesta que para
          el ejercicio de los derechos ARCO.
        </p>
      </li>

      <li>
        <b>Medios para limitar el uso o divulgación de los datos personales </b>
        <p>
          “Hernández Carreño” podrá utilizar los datos recabados para enviar
          información que considere importante respecto a temas legales o de la
          actividad que desarrolla. Esta información se dará a conocer mediante
          avisos, mensajes o boletines de correo electrónico, los cuales sólo
          serán enviados a usted y a aquellos contactos registrados en
          www.quieroregistrarmimarca.com para tal propósito, esta indicación
          podrá ser modificada o cancelada por el “Usuario” en cualquier momento
          enviando un correo a contacto@hernandezcarreño.com.
        </p>
      </li>

      <li>
        <b
          >Mecanismos para recabar datos personales de manera automática en
          medios remotos o locales de comunicación electrónica</b
        >
        <p>
          El sitio de “Hernández Carreño” utiliza Cookies o elementos similares.
          Las cookies son archivos de texto que quedan almacenados en el disco
          duro de su ordenador cuando visita algunos sitios web. Se utilizan las
          cookies para registrar si usted ha visitado anteriormente el sitio web
          o si es la primera vez que lo hace, así como para identificar
          aplicaciones del sitio web en las que pueda tener mayor interés. Las
          cookies pueden mejorar su experiencia on-line, por ejemplo, recordando
          sus contraseñas y mostrándole sus preferencias cuando visita un sitio
          web en particular. En principio, la mayoría de los navegadores están
          configurados para aceptar cookies, en la opción ayuda de la barra de
          herramientas de la mayoría de navegadores le dirá cómo dejar de
          aceptar cookies nuevas, cómo recibir notificaciones sobre cookies
          nuevas, y cómo deshabilitar las cookies existentes.
        </p>
      </li>

      <li>
        <b>Aceptación y actualización de los términos</b>
        <p>
          Este aviso de privacidad constituye un acuerdo legal entre usted y
          “Hernández Carreño”.
        </p>
        <p>
          “Hernández Carreño” se reserva el derecho de actualizar o modificar en
          cualquier momento, total o parcialmente, el presente Aviso de
          Privacidad, a efecto de atender cualquier novedad legislativa o
          jurisprudencial, así como por políticas internas o requerimientos
          necesarios para prestar los servicios que ofrece, por lo que en caso
          de que exista alguna actualización o modificación se pondrá a su
          disposición la versión actualizada directamente en la página de
          Internet www.quieroregistrarmimarca.com. Si el usted utiliza los
          servicios en el sitio web de “QRM, Quiero Registrar mi Marca” ® se
          entenderá que ha leído, entendido y acordado los términos antes
          expuestos.
        </p>
      </li>

      <li>
        <b>Autoridad</b>
        <p>
          Si usted considera que han sido vulnerados sus derechos respecto de la
          protección de datos personales, tendrá el derecho de acudir al
          Instituto Federal de Acceso a la Información y Protección de Datos
          (IFAI) y llevar a cabo los procedimientos establecidos para
          salvaguardar sus datos.
        </p>
      </li>

      <li>
        <b>Fecha de la última actualización</b>
        <p>
          El Aviso de Privacidad ha sido modificado el día 02 de marzo de 2021.
        </p>
      </li>
    </ol>

    <hr class="divider-modal text-center" />
    <p class="text-center">
      Av. Baja California 146 Roma Sur Cuauhtémoc 06760, Ciudad de México.
      <br />
      contacto@hernandezcarreno.com / 52198599
      <br />
      <a href="http://hernandezcarreno.com/" target="blank"
        >www.hernandezcarreno.com</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'AvisoPrivacidad',
}
</script>
