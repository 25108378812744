import axios from 'axios'
let arrayUsuario= new Array(6);
export const contadorUsuarios = {
    datosUsuario: function(ipUsuario,region,fecha,pais,seccion,esPagado) {
      arrayUsuario[0]=ipUsuario;
      arrayUsuario[1]=region;
      arrayUsuario[2]=fecha;
      arrayUsuario[3]=pais;
      arrayUsuario[4]=seccion;
      arrayUsuario[5]=esPagado;
    },
    actualizarDatos:function(actualizarSeccion){
       arrayUsuario[4]=actualizarSeccion;
    },

    realizoPago:function(realizoElPago){
      arrayUsuario[5]=realizoElPago;
      this.enviarDatos();
    },
    enviarDatos:function(){
      let data={
        ipUsuario:arrayUsuario[0],
        region:arrayUsuario[1],
        fecha:arrayUsuario[2],
        seccion:arrayUsuario[4],
        esPagado:arrayUsuario[5]
      }
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }
      
      console.log("Datos a enviar :"+data);
      try {
        const solUrlProd ='https://www.datidev.com:8080/QRM-API/contador/contadorUser'
          axios.post(solUrlProd, data, axiosConfig).then(
          
        )
      } catch (e) {
        console.log("error api contador user "+e)
      }
  }
  };

  
