<template>
  <section>
    <aviso-privacidad></aviso-privacidad>
  </section>
</template>

<script>
import AvisoPrivacidad from '../components/AvisoPrivacidad.vue'
export default {
  name: 'AvisoPrivacidadPage',
  components: { AvisoPrivacidad },
}
</script>
