<template>
  <div id="preguntas-frecuentes">
    <div class="container">
      <h2 class="subtitle">PREGUNTAS FRECUENTES</h2>
      <hr class="divider" />

      <b-row>
        <b-col>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(p, i) in preguntas" :key="i">
              <b-card-question header-tag="card" class="p-1 c-header" role="tab">
                <b-button
                  block
                  v-b-toggle="`accordion-${i}`"
                  class="text-left  btn-question"
                  variant=" "
                  >{{ p.pregunta }}</b-button
                >
              </b-card-question>
              <b-collapse
                :id="`accordion-${i}`"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body >
                  <b-card-text variant="outline-dange" v-if="i<9"  class="col-sm-7  texto-justificado" v-html="p.respuesta
                  ">{{

                  }}</b-card-text>
                  <b-card-text variant="outline-dange" v-if="i==9"  class="col-sm-12 texto-justificado" v-html="p.respuesta
                  ">{{

                  }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreguntasFrecuentes',
  data() {
    return {
      preguntas: [
        {
          pregunta: '¿Qué es una marca?',
          respuesta:
            'Es el signo distintivo más importante de una empresa, toda vez que ayuda al público consumidor a diferenciar productos y servicios de sus competidores en el mercado.',
        },
        {
          pregunta: '¿Cuánto tiempo demora el proceso?',
          respuesta:
            'Una vez presentado ante la autoridad, la tramitación es de 6 meses a 1 año.',
        },
        {
          pregunta: '¿Cuál es la vigencia del título de marca?',
          respuesta:
            'Es de 10 años en México, mismos que se renuevan por el mismo periodo de forma ilimitada.',
        },
        {
          pregunta: 'Quiero registrar mi marca, ¿Qué debo hacer?',
          respuesta:
            'Debes tener el nombre de tu marca, el logo (si es que tienes) y definir el producto o servicio a utilizar, elige el paquete que más te convenga y nosotros nos encargamos del resto. Podrás revisar el avance en todas las etapas del procedimiento.',
        },
        {
          pregunta: '¿Puedo registrar mi marca en el extranjero?',
          respuesta:
            'Si, La puedes registrar en algún país en específico o bien utilizar uno de los diferentes tratados internacionales para registrar en varios países. En <i><span class="colored"> QRM – Quiero Registrar mi Marca, ®</span></i>  contamos con abogados especializados en Propiedad Intelectual que podrán solicitar el registro de tu marca con protección internacional.',
        },
        {
          pregunta: '¿Qué beneficios tiene registrar tu marca?',
          respuesta:
            'La protección de tu marca es una garantía, además tendrás el derecho al uso exclusivo en territorio nacional y/o internacional, así mismo a usar las siglas “M.R.” o el símbolo ®, te protege de competencia desleal, se puede convertir en el activo más importante de tu negocio y existe la posibilidad de conceder franquicias o licencias de uso.',
        },
        {
          pregunta: '¿Cuáles son las formas de pago?',
          respuesta:
            'Tarjeta de débito o crédito, transferencia bancaria.',
        },
        {
          pregunta: '¿A partir de qué momento se encuentra protegida mi marca?',
          respuesta:
            'Desde el otorgamiento del título, sin embargo, una vez ingresada la solicitud de registro de marca, obtienes un derecho de preferencia ante terceros al ser el primero en tiempo.',
        },
        {
          pregunta:
            'Tiempo atrás solicité el registro de mi marca, pero no me la otorgaron ¿Ustedes me pueden ayudar?',
          respuesta:
            'Si, nosotros te podemos ayudar a presentar tu solicitud de registro correctamente, desahogar requisitos notificados por la autoridad, contestar anterioridades de marca, solicitar tu certificado de registro de marca en caso de que no cuentes con éste, conceder franquicias o licencias de uso, etc.',
        },
        {
          pregunta: 'Información sobre tipos de marca:',
          respuesta:`
              <p class="col-sm-7">
                Hay varios tipos de marcas dependiendo de los elementos que la
                conforman, existen las tradicionales, y no tradicionales, las
                cuales se describen a continuación.
              </p>
              <br />
              <h4 class="text-center">Marcas tradicionales</h4>
              <br />
              <div class="row text-center">
                <div class="col">
                  <p><strong>Nominativas</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('nominativas.png')}" alt="" width="100%" />
                  <p class="small">
                    Consistente en la combinación de letras y números.
                  </p>
                </div>
                <div class="col">
                  <p><strong>Innominada</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('innominada.png')}" alt="" width="100%" />
                  <p class="small">
                    Son aquellas que cuentan con símbolos gráficos, logotipos,
                    dibujos, etc.
                  </p>
                </div>
                <div class="col">
                  <p><strong>Mixtas</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('mixtas.png')}" alt="" width="100%" />
                  <p class="small">
                    Son aquellas marcas que combinan los dos tipos anteriores.
                  </p>
                </div>
                <div class="col">
                  <p><strong>Tridimensionales</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('tridimensionales.png')}" alt="" width="100%" />
                  <p class="small">
                    Tienen sus elementos gráficos en tres dimensiones.
                  </p>
                </div>
              </div>

              <br />
              <br />
              <h4 class="text-center">Marcas NO tradicionales</h4>
              <br />
              <div class="row text-center">
                <div class="col">
                  <p><strong>Sonoras</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('sonoras.png')}" alt="" width="100%" />
                  <p class="small">
                    Consisten en una sucesión de sonidos que caracterizan el
                    producto o servicio que se ofrece.
                  </p>
                </div>
                <div class="col">
                  <p><strong>Olfativa</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('olfativa.png')}" alt="" width="100%" />
                  <p class="small">
                    Se caracterizan por tener un aroma o esencia.
                  </p>
                </div>
                <div class="col">
                  <p><strong>Imagen Comercial</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('comercial.png')}" alt="" width="100%" />
                  <p class="small">
                    Es la presentación visual de un producto, incluyendo formas,
                    colores, texturas, entre otros
                  </p>
                </div>
                <div class="col">
                  <p><strong>Holográfica</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('holografica.png')}" alt="" width="100%" />
                  <p class="small">
                    Son imágenes que parecen tener movimiento.
                  </p>
                </div>
                <div class="col">
                  <p><strong>De Certificación</strong></p>
                  <hr class="divider short" />
                  <img src="${this.getImage('certificacion.png')}" alt="" width="100%" />
                  <p class="small">
                    Es un signo que certifica a productos o servicios que
                    cumplen con ciertas características o calidad determinada.
                  </p>
                </div>
              </div>
            
            `,
        },
      ],
    }
  },
  methods: {
    getImage(img) {
      return require(`@/assets/img/faq/${img}`)
    }
  }
}


</script>
