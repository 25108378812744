<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <span> {{item.name}}</span>
      <b-icon
        icon="exclamation-circle"
        variant="warning"
        :id="'tooltip-target-' + item.id"
      ></b-icon>

      <b-popover
        style="overflow-y:auto;"
        :target="'tooltip-target-' + item.id"
        triggers="hover"
        placement="left"
      >
        <template #title>{{ item.name }}</template>
        {{ item.description }}
      </b-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: { required: true},
    searchText: { required: true },
  },
}
</script>
