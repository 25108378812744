<template>
  <div id="terminos" class="container text-justify" style="margin-top: 150px;">
    <h2 class="text-center">Términos y Condiciones</h2>
    <hr class="divider text-center" />

    <p>
      El presente contrato describe los términos y condiciones aplicables a los
      servicios ofrecidos por HERNÁNDEZ CARREÑO LEGAL SERVICES, S.C., a través
      del sitio www.quieroregistrarmimarca.com (en adelante el “Sitio”) a usted
      (en adelante el 'Usuario'). Estos términos y condiciones tienen el
      carácter de obligatorios y vinculantes. El uso por parte del Usuario de
      este sitio constituye un acuerdo a todos los términos y condiciones, por
      tal motivo sugerimos al usuario leerlos atentamente. Utilizando el
      “Sitio”, el “Usuario” acuerda estos términos y condiciones de uso como así
      también cualquier otro término, pauta o regla aplicable a todo o una parte
      de este Sitio. Si el “Usuario” no está de acuerdo con estos términos y
      condiciones de uso, debe abstenerse de utilizar el “Sitio” y
      consecuentemente los servicios ofrecidos.
    </p>

    <p class="text-center"><b>CLÁUSULAS</b></p>
    <p>
      <b>PRIMERA.- Solicitud de Información.-</b> Si para solicitar exámenes de
      viabilidad de registro de marcas, cotización de precios, o cualquier otra
      información ofrecida por el “Sitio” se requiere consignar cierta
      información, el “Usuario” queda obligado a cumplimentar dichos procesos,
      suministrando a el “Sitio” información verdadera, actualizada, completa y
      exacta. El “Sitio” no se responsabiliza por la información (en adelante
      los 'Datos Personales') suministrados en estos procesos por el “Usuario”.
      El “Usuario”, en cualquier caso, se responsabiliza y garantiza, la
      vigencia, veracidad, integridad y exactitud de los Datos Personales
      ingresados. El “Sitio” no es responsable por errores o retrasos en la
      respuesta a cualquier pedido de información, cotización o solicitud de
      servicio que derive de información anticuada, falsa, incompleta o inexacta
      proporcionada por el “Usuario”. El “Sitio” se reserva el derecho de
      rechazar cualquier solicitud de información o cotización, sin que esté
      obligado a comunicar o exponer las razones de su decisión y sin que ello
      genere algún derecho a indemnización o resarcimiento. El “Usuario”
      entiende y acepta que: (a) autoriza en forma expresa a que el “Sitio” se
      ponga en contacto vía e-mail, teléfono o cualquier otro medio que haya
      indicado a los fines de enviarle información y ofertas sobre nuestros
      servicios; y b) autoriza a el “Sitio”, a guardar la información que se
      remite.

      <br /><br /><b>SEGUNDA.- Prestación de Servicio.-</b> A través del
      “Sitio”, se comercializan servicios de registro de marcas y avisos
      comerciales (slogan), así como consultoría en propiedad intelectual y
      servicios legales. A. Sobre los Servicios en General: El “Usuario”
      entiende y acepta que: (i) A menos que en forma expresa y por escrito se
      establezca lo contrario por el “Sitio”, ningún contenido del “Sitio” es
      una promesa de servicio, ni precio específico; (ii) es el “Usuario" el
      único y exclusivo responsable por la exactitud y veracidad de la
      información enviada a la hora de realizar una solicitud de servicios;
      (iii) Haciendo clic en el botón 'Enviar', o cualquier otro botón similar,
      así como brindando su información personal vía telefónica, está: (a)
      sometiendo una solicitud de servicio, la cual podrá ser aceptada o
      rechazada sin que ello genere algún derecho a indemnización o
      resarcimiento, (b) autoriza en forma expresa a que nos pongamos en
      contacto vía e-mail, teléfono o cualquier otro medio que haya indicado, y
      (c) autoriza al “Sitio”, a guardar la información que se remite. Sin
      perjuicio de ello, el “Usuario” podrá solicitar a través de medio
      fehaciente que dicha información le sea suministrada o removida de la base
      de datos; (iv) Los pagos realizados por el “Usuario” no son reembolsables,
      bajo ninguna causa; (v) El mero hecho del pago no genera la obligación del
      “Sitio” a la prestación del servicio, en tanto el “Usuario” no cumplimente
      el envío de la información y/o documentación requerida. B. Sobre el
      servicio de registro de marcas en particular: El “Usuario” entiende y
      acepta que: (i) Todo examen de viabilidad de registro de marcas emitido
      por el “Sitio” reviste el carácter de opinión de parte, en consecuencia el
      hecho de recibir un informe positivo no implica que su trámite de registro
      de marca quede exento de anterioridades u oposiciones; (ii) El trámite de
      registro de marcas, es un proceso administrativo llevado adelante por el
      “Sitio”, en representación del cliente, ante el Instituto Mexicano de la
      Propiedad Industrial (en adelante IMPI); (iii) El mero hecho de iniciar
      una solicitud de registro de marca, no garantiza que la marca vaya a ser
      concedida, el otorgamiento de los registros es facultad potestativa de la
      autoridad y no responsabilidad de Hernández Carreño Abogados; (iv) Los
      montos abonados por el “Usuario” al momento de solicitar un registro de
      marcas, cubren los derechos oficiales a pagar a IMPI así como los
      honorarios profesionales para llevar adelante el trámite de registro de
      marca; sin embargo no cubren los derechos oficiales y honorarios
      profesionales que eventualmente podrían corresponder, en el caso que el
      expediente reciba requerimientos por parte de IMPI u oposiciones de
      terceros; (v) en el caso que el “Usuario” contrate el registro de una
      marca sin incluir logotipo, la solicitud de registro será presentada como
      marca nominativa, y el formulario será completado ingresando la
      información tal como la envío el cliente, bajo su exclusiva
      responsabilidad; (vi) en el caso que el “Usuario” contrate el registro de
      una marca incluyendo un logotipo, la solicitud de registro será presentada
      como marca mixta, y el formulario será completado teniendo en cuenta el
      texto incorporado en el logotipo, para poder dar cumplimiento a la
      normativa en vigor; (vii) en el caso que el “Usuario” envíe un logotipo,
      que no contenga texto, la solicitud de registro será presentada como marca
      figurativa, y no se incluirán en el formulario palabras ni texto alguno;
      (viii) en el caso que el Usuario desee realizar cambios a su solicitud de
      registro, luego de haber sido iniciado el expediente, deberá hacerse cargo
      de los montos correspondientes a derechos oficiales y honorarios
      profesionales para la apertura de un nuevo expediente; (x) en el caso que
      el “Usuario” desee registrar su marca a nombre de dos o más titulares,
      deberá cubrir el monto adicional por la elaboración de las reglas de uso,
      mismas que serán requeridas por la autoridad; (x) en el caso que el
      “Usuario” desee llevar la resolución negativa de su marca a recurso de
      revisión, juicio contencioso o juicio de amparo, deberá cubrir el monto
      adicional por honorarios de los abogados.

      <br /><br /><b>TERCERA.- Responsabilidad.- </b> El “Usuario” entiende y
      acepta que la responsabilidad del “Sitio”, en lo que respecta a la
      prestación de servicios tiene los siguientes límites: A. Con respecto a
      todos los servicios en general: (i) En ningún caso el “Sitio”, así como a
      sus abogados, agentes de la propiedad industrial, directivos,
      administradores, representantes, empleados y proveedores externos serán
      responsables de cualquier daño directo, indirecto, incidental, punitivo,
      especial o consecuente, incluso si se informa de la posibilidad de dichos
      daños por adelantado derivado de: (a) la información brindada en cualquier
      examen de viabilidad de marcas, derechos de autor, registro de software, o
      cualquier otro tipo de protección de derechos de propiedad intelectual;
      (b) la falta de contestación de requerimiento recibido en cualquier
      trámite de registro de marcas, patentes, modelos de utilidad, derechos de
      autor, registro de software, o cualquier otro tipo de protección de
      derechos de propiedad intelectual, cuando dicha contestación no haya sido
      realizada en plazo por caso fortuito, fuerza mayor o cualquier otra causa
      fuera del control del “Sitio”, tal como la falta por parte del cliente del
      envío de la información y/o documentación solicitada, así como la falta de
      pago de tasas, aranceles u honorarios devengados. B. Con respecto al
      servicio de registro de marcas: (i) Los exámenes de viabilidad emitidos de
      forma gratuita, incluyen exclusivamente marcas idénticas dentro de la
      clase correspondiente; (ii) Los exámenes de viabilidad incluyen marcas con
      similitud fonética dentro de la clase correspondiente, mismos que tienen
      un costo adicional; (iii) Debido a la naturaleza subjetiva de este tipo de
      investigación, es imposible garantizar que cualquier examen de viabilidad,
      esté completo o sin errores. Por lo tanto, no existe garantía, expresa,
      implícita o contractual, con respecto a cualquier examen de viabilidad
      emitido; (c) Con respecto al uso del “Sitio” web
      www.quieroregistrarmimarca.com: (i) Todo el contenido del “Sitio” está
      proporcionado solamente para fines informativos y educativos, y no
      pretende realizar un detalle pormenorizado de los servicios enunciados. El
      contenido del “Sitio” puede incluir inexactitudes o errores tipográficos,
      por lo cual el “Sitio” no asume responsabilidad alguna por tales
      inexactitudes o errores, daños, perjuicios o pérdidas que el “Usuario”
      pueda sufrir causados por fallas en el sistema, en el servidor o en
      Internet, como así tampoco por cualquier virus que pudiera infectar la
      computadora del “Usuario” como consecuencia del acceso, uso o examen del
      “Sitio” o a raíz de cualquier transferencia de datos, archivos, imágenes,
      textos, o audio contenidos en el mismo. Los “Usuarios” no podrán imputarle
      al “Sitio” responsabilidad alguna ni exigir pago por lucro cesante, en
      virtud de perjuicios resultantes de dificultades técnicas o fallas en los
      sistemas, en el servidor o en Internet. El “Sitio” no garantiza el acceso
      y uso continuado o ininterrumpido del “Sitio”. El sistema puede
      eventualmente no estar disponible debido a dificultades técnicas o fallas
      de Internet, o por cualquier otra circunstancia ajena al “Sitio”; en tales
      casos se procurará restablecerlo con la mayor prontitud posible sin que
      por ello pueda imputársele al “Sitio” algún tipo de responsabilidad.

      <br /><br /><b>CUARTA.- Prohibiciones.- </b> Queda prohibido al “Usuario”
      y sin limitación, la utilización del “Sitio” para: (i) realizar consultas
      de marcas cuyo registro previo es público y notorio; (ii) realizar
      consultas con el mero fin de espionaje industrial y/o análisis de
      competencia; (iii) enviar o transmitir archivos que contengan virus u
      otras características destructivas que puedan afectar el normal
      funcionamiento de una computadora ajena y/o del “Sitio”; (iv) violar
      cualquier ley aplicable, cualquiera fuese su naturaleza, internacional,
      nacional, estatal o municipal; (v) solicitar información o servicios
      utilizando un nombre falso, erróneo o inexistente, ya sea como persona
      física o jurídica; y (v) enviar o transmitir información de propiedad de
      terceros o que pudiera afectar adversamente la imagen y/o los derechos de
      terceros.

      <br /><br /><b>QUINTA.- Propiedad Intelectual.- </b> Todo el “Sitio”, a
      menos que se indique expresamente lo contrario, está protegido por las
      leyes y tratados internacionales de derechos de autor y marcas, por lo
      cual todo contenido y sin limitación a: el texto, imágenes, archivos,
      bases de datos y otros materiales ofrecidos en
      www.quieroregistrarmimarca.com son propiedad de “Hernández Carreño”.
      Asimismo, el “Sitio” puede contener marcas y logotipos relativos a otros
      productos y servicios, tales como los pertenecientes a entidades de
      certificación de calidad, proveedores, tarjetas de crédito, etc.; todos
      los cuales son propiedad exclusiva de sus respectivos dueños. El uso
      indebido y la reproducción total o parcial de dichos contenidos quedan
      prohibidos, salvo autorización expresa y por escrito de sus respectivos
      dueños.

      <br /><br /><b>SEXTA.- Indemnidad.- </b> El “Usuario” indemnizará y
      mantendrá indemne al “Sitio”, así como a sus agentes, directivos,
      administradores, representantes, empleados, proveedores, y socios, de
      terceros por cualquier reclamo o demanda de otros “Usuarios” o terceros
      por su actividades en el “Sitio” o por su incumplimiento los Términos y
      Condiciones de uso y demás Políticas que se entienden incorporadas al
      presente o por la violación de cualesquier ley o derecho de terceros,
      incluyendo los honorarios de abogados en una cantidad razonable.

      <br /><br /><b
        >SÉPTIMA.- Modificaciones de los términos y condiciones.-
      </b>
      El “Sitio” se reserva el derecho de modificar, agregar o quitar en parte o
      en su totalidad estos términos y condiciones de uso, como así también
      cualquier otro término, pauta o regla. Los cambios serán informados al
      “Usuario” en esta sección informando la fecha de la última actualización.
      Dentro de los 5 (cinco) días siguientes a la publicación de las
      modificaciones introducidas, el “Usuario” deberá comunicar por e-mail si
      no acepta las mismas; en ese caso el “Sitio” procederá a eliminar de sus
      bases de datos toda la información vinculada a dicho “Usuario”. Vencido
      este plazo, se considerará que el “Usuario” acepta los nuevos términos y
      condiciones.

      <br /><br /><b>OCTAVA.- Ley aplicable y jurisdicción.- </b> El “Usuario”
      acepta que todo conflicto de intereses o diferencia que pueda surgir como
      consecuencia del uso del “Sitio” será resuelto de acuerdo con la
      legislación vigente en los Estados Unidos Mexicanos, sometiéndose a la
      jurisdicción y competencia de los Tribunales Ordinarios de la Ciudad de
      México, renunciando expresamente a todo otro fuero o jurisdicción que
      pudiere corresponder.

      <br /><br /><b>NOVENA.- Domicilio.- </b> HERNÁNDEZ CARREÑO LEGAL SERVICES,
      S.C. fija su domicilio para toda notificación o comunicación en relación a
      estos términos y condiciones en Av. Baja California 146,
      Colonia Roma Sur, Cuauhtémoc, C.P. 06760 en la Ciudad de México.

      <br /><br /><b>DÉCIMA.- Fecha de la última actualización.- </b>Los
      términos y condiciones han sido modificados el día 26 de febrero de 2021.
    </p>

    <hr class="divider-modal text-center" />
    <p class="text-center">
      Av. Baja California 146 Roma Sur Cuauhtémoc 06760, Ciudad de México.
      <br />contacto@hernandezcarreno.com / 52198599 <br /><a
        href="http://hernandezcarreno.com/"
        target="blank"
        >www.hernandezcarreno.com</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'TerminosCondiciones',
}
</script>
