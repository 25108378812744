<!--cambio-->
<template id="top">
  <b-container>
    <template>
      <b-row style="margin-top: 140px">
        <b-col sm="12" md="6">
          <b-card
            :title="textTitle"
            :header-html="textHead"
            sub-title=" "
            tag="article"
            style="max-width: 40rem"
            class="pt-4"
          >
            <br />
            <b-form-invalid-feedback :state="state"
            >
              <h6>
                <p style="color: chocolate">
                  Para continuar acepte términos, condiciones y Aviso de
                  privacidad”
                </p>
              </h6>
            </b-form-invalid-feedback>
            <b-card-text v-if="!state">
              <b-row style="margin-top: 40px; height: 20vh">
                <b-col md="12" class="d-flex justify-content-start">
                  <b-form-checkbox-group
                    v-model="value"
                    :options="options"
                    :state="state"
                    name="checkbox-validation"
                  >
                    <b-form-valid-feedback :state="state"
                    >¡Gracias!
                    </b-form-valid-feedback
                    >
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-card-text>
            <b-card-text class="pt-4" v-else>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errors.length > 0"
              >
                <li v-for="error in errors" :key="error">
                  {{ error }}
                </li>
              </div>
              <b-form>
                <template v-if="currentStep === 1">
                  <b-form-group
                    label="Nombre completo: *"
                    label-for="input-1"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="nombreSolicitante"
                      id="input-22"
                      type="text"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Teléfono: *"
                    label-for="input-1"
                    label-size="sm"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      :maxlength="maxLPhone"
                      v-model="telefonoSolicitante"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Celular:"
                    label-for="input-1"
                    label-size="sm"
                  >
                    <b-form-input
                      id="input-1"
                      type="number"
                      size="sm"
                      v-model="celularSolicitante"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Correo electrónico: *"
                    label-for="input-1"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="correoSolicitante"
                      id="input-15"
                      type="email"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="País: *"
                    label-for="pais"
                    label-size="sm"
                  >
                    <b-form-select
                      id="optionSearch"
                      v-model="paisSolicitante"
                      :options="listaPais"
                      @change="searchCountry()"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                  </b-form-group>
                  <b-row>
                    <b-form-group
                      label="Código postal: *"
                      label-for="codigoP"
                      label-size="sm"
                      class="mt-3 col-sm-6"
                    >
                      <b-form-input
                        v-model="codigoPostal"
                        type="number"
                        size="sm"
                        class="mt-4"
                        :maxlength="maxCodigo"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Estado: *"
                      label-for="estado"
                      label-size="sm"
                      class="mt-3 col-sm-6"
                    >
                      <b-form-select
                        id="optionSearchEstado"
                        v-model="estadoSolicitante"
                        :options="listaEstados"
                        size="sm"
                        class="mt-3"
                      ></b-form-select>
                    </b-form-group>
                  </b-row>
                  <b-form-group
                    label="Ciudad: *"
                    label-for="ciudad"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="ciudadSolicitante"
                      id="ciudad"
                      type="text"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-row style="padding-bottom: 15px">
                    <b-form-group
                      label="Entre que Calle 1: *"
                      label-for="calle1"
                      label-size="sm"
                      class="mt-3 col-sm-6"
                    >
                      <b-form-input
                        id="calle1"
                        v-model="calleASolicitante"
                        type="text"
                        size="sm"
                        class="mt-3"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Entre que Calle 2: *"
                      label-for="calle2"
                      label-size="sm"
                      class="mt-3 col-sm-6"
                    >
                      <b-form-input
                        v-model="calleBSolicitante"
                        id="calle2"
                        size="sm"
                        class="mt-3"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                </template>
                <template v-else-if="currentStep === 2">
                  <b-row style="padding-bottom: 15px">
                    <b-form-group
                      id="input-group-1"
                      label="Seleccione: *"
                      label-for="input-1"
                      label-size="sm"
                      class="mt-3 col-sm-7"
                    >
                      <b-form-select
                        v-model="clasificacion"
                        :options="generalProducts"
                        size="sm"
                        class="mt-3"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      id="input-group-1"
                      label="Tipo producto: *"
                      label-for="input-1"
                      label-size="sm"
                      class="mt-3 col-sm-5"
                    >
                      <b-form-select
                        v-model="selectedProduct"
                        :options="products"
                        @change="checkProduct($event)"
                        size="sm"
                        class="mt-3"
                      ></b-form-select>
                    </b-form-group>
                  </b-row>

                  <b-form-group
                    label="Productos y/o servicios: *"
                    label-for="input-1"
                    label-size="sm"
                    class="space"
                  >
                    <v-autocomplete
                      :items="items"
                      v-model="productoSolicitante"
                      :get-label="getLabel"
                      :component-item="template"
                      :input-attrs="attribu"
                    >
                    </v-autocomplete>
                  </b-form-group>
                  <b-form-group
                    v-if="bndCountry"
                    id="input-group-1"
                    label="Seleccione País"
                    label-for="input-1"
                    label-size="sm"
                  >
                    <b-form-select
                      v-model="selectedCountry"
                      :options="countryList"
                      multiple
                      :select-size="4"
                      size="sm"
                      class="mt-3"
                    ></b-form-select>
                    <!-- <div class="mt-3">Paises seleccionados: <strong>{{ selectedCountry.value }}</strong></div>-->
                  </b-form-group>

                  <b-form-group
                    label="Nombre de lo que quieres registrar: *"
                    label-for="input-13"
                    label-size="sm"
                    class="space"
                  >
                    <b-form-input
                      v-model="marcaSolicitante"
                      id="input-13"
                      type="text"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Logotipo" label-size="sm" class="space">
                    <b-form-text id="password-help-block">
                      Adjunte la imagen tal y como desea que lo registremos en
                      formato jpg, png, jpeg.
                    </b-form-text>
                    <input
                      type="file"
                      size="sm"
                      accept="image/*"
                      @change="handleImage($event)"
                      id="file-input"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="bndCountry"
                    id="input-group-1"
                    label="Tipo de impresión: *"
                    label-for="input-13"
                    label-size="sm"
                  >
                    <b-form-select
                      v-model="tipo_impresion"
                      :options="tipoImpresion"
                      size="sm"
                      required
                    ></b-form-select>
                  </b-form-group>

                  <b-form-checkbox
                    size="sm"
                    id="checkbox-1"
                    v-model="alreadyInUse"
                    name="checkbox-1"
                    value="accepted"
                  >
                    Ya he utilizado mi marca
                  </b-form-checkbox>
                  <b-form-group
                    v-if="alreadyInUse === 'accepted'"
                    label="Fecha aproximada en la que empecé a utilizar mi marca:"
                    label-for="input-11"
                    label-size="sm"
                  >
                    <b-form-datepicker
                      placeholder="Elegir fecha"
                      v-model="dateSolicitante"
                      :max="maxDate"
                      locale="es"
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group
                    label="Observaciones:"
                    label-for="textArea"
                    label-size="sm"
                    class="space"
                  >
                    <b-col sm="12">
                      <b-form-textarea
                        id="textArea"
                        type="text"
                        size="sm"
                        required
                        v-model="observaciones"
                      ></b-form-textarea>
                    </b-col>
                  </b-form-group>
                </template>
                <template v-else-if="currentStep === 3 && !isSaving">
                  <b-card class="mb-2 card-payment">
                    <b-card-text>
                      <h5><strong>Información tarjeta crédito</strong></h5>
                      <hr class="divider-dashed" />
                      <b-form-group
                        label="Número tarjeta: *"
                        label-for="input-111"
                        label-size="sm"
                      >
                        <b-form-input
                          id="input-111"
                          v-model="card.number"
                          type="text"
                          size="sm"
                          @keypress="onlyNumber"
                          :maxlength="maxLCard"
                          :minlength="minLCard"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-row>
                        <b-col>
                          <b-form-group
                            id="input-group-1"
                            label="Fecha Exp.*"
                            label-for="input-1"
                            label-size="sm"
                          >
                            <b-form-input
                              v-model="card.exp_month"
                              autocomplete="off"
                              @keypress="onlyNumber"
                              :maxlength="maxLMonth"
                              :minlength="minLMonth"
                              id="input-1"
                              placeholder="Mes"
                              type="text"
                              size="sm"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            id="input-group-2"
                            label="*"
                            label-for="input-2"
                            label-class="lay"
                            label-size="sm"
                          >
                            <b-form-input
                              v-model="card.exp_year"
                              id="input-2"
                              @keypress="onlyNumber"
                              :maxlength="maxLYear"
                              :minlength="minLYear"
                              autocomplete="off"
                              placeholder="Año"
                              type="text"
                              size="sm"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            id="input-group-1"
                            label="CVC *"
                            label-for="input-3"
                            label-size="sm"
                          >
                            <b-form-input
                              v-model="card.cvc"
                              id="input-3"
                              type="password"
                              @keypress="onlyNumber"
                              :maxlength="maxLCVC"
                              :minlength="minLCVC"
                              autocomplete="off"
                              size="sm"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-form-group
                        label="Nombre tarjetahabiente:*"
                        label-for="input-1111"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="card.name"
                          id="input-1111"
                          placeholder="Ingrese su nombre"
                          type="text"
                          size="sm"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Apellido tarjetahabiente:*"
                        label-for="input-2222"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="card.lastName"
                          id="input-2222"
                          placeholder="Ingrese su apellido"
                          type="text"
                          size="sm"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-card-text>
                  </b-card>
                </template>
                <template v-else-if="currentStep === 4 && isPaid">
                  <b-alert show dismissible fade @dismissed="resetSteps()">
                    <p>Solicitud enviada exitosamente.</p>
                    <p>
                      Recibirás un correo electrónico con la siguiente
                      información:
                    </p>
                    <ul>
                      <li>Usuario y contraseña</li>
                      <li>Documentación requerida</li>
                    </ul>
                    <small
                    >*Podrás revisar nuestro trabajo en todas las etapas del
                      procedimiento.</small
                    >
                  </b-alert>
                </template>
                <b-form-text
                  id="password-help-block"
                  v-if="!isSaving && !isPaid"
                >
                  * Requeridos
                </b-form-text>
              </b-form>
            </b-card-text>
            <div class="d-flex justify-content-end">
              <b-button
                href="#"
                @click="prevStep()"
                v-if="currentStep > 1 && !isSaving && !isPaid"
                class="btn-qrm-primary mb-4"
              >Anterior
              </b-button
              >
              <template v-if="currentStep === 3 && !isSaving">
                <input type="hidden" v-model="habilitado">
                <b-button :disabled="tipoEstado"
                          href="#"
                          @click="obtenerToken()"
                          class="float-right btn-qrm-primary mb-4 ml-2"
                >Pagar
                </b-button
                >
              </template>
              <template v-else>
                <b-button
                  href="#"
                  @click="nextStep()"
                  v-if="!isSaving && !isPaid && state"
                  class="float-right btn-qrm-primary mb-4 ml-2"
                >Siguiente
                </b-button
                >
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col class="d-none d-sm-block" v-if="currentStep != 3">
          <b-row
            class="d-flex flex-column"
            style="padding-top: 28px; padding-left: 30px; padding-bottom: 30px"
          >
            <img
              src="@/assets/img/solicitud/QRM-Banner.jpeg"
              class="img-fluid"
              alt="opt1"
              style=" border-radius: 20px;
                      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15)"
            />
          </b-row>
        </b-col>
        <b-col v-else>
          <b-card>
            <template v-if="isSaving">
              <div
                class="
                  d-flex
                  justify-content-center
                  mb-3
                  flex-column
                  align-items-center
                "
              >
                <strong>Guardando solicitud...</strong>
                <b-spinner variant="primary" label="Loading..."></b-spinner>
              </div>
            </template>
            <template v-else>
              <h5><strong>Resúmen orden</strong></h5>
              <hr class="divider-dashed" />

              <div class="item-cart pt-3 mt-4">
                <div class="d-flex justify-content-between">
                  <p class="font-weight-light">Servicio:</p>

                  <template v-if="clasificacion == 1">
                    <p class="font-weight-bolder">Dictamen de Viabilidad</p>
                  </template>
                  <template v-if="clasificacion == 2">
                    <p class="font-weight-bolder">
                      Registro de marca o aviso comercial (slogan)
                    </p>
                  </template>
                  <template v-if="clasificacion == 3">
                    <p class="font-weight-bolder">
                      Registro de marca o aviso comercial (slogan)
                    </p>
                  </template>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="font-weight-light">Tipo de registro:</p>

                  <template v-if="clasificacion == 1 && bndProducto == true">
                    <p class="font-weight-bolder">Marca</p>
                  </template>
                  <template v-if="clasificacion == 1 && bndProducto == false">
                    <p class="font-weight-bolder">Aviso Comercial (Slogan)</p>
                  </template>

                  <template v-if="clasificacion == 2 && bndCountry == false">
                    <p class="font-weight-bolder">Nacional</p>
                  </template>
                  <template v-if="clasificacion == 3 && bndCountry == false">
                    <p class="font-weight-bolder">Nacional</p>
                  </template>
                  <template
                    v-if="
                      (clasificacion == 2 && bndCountry == true) ||
                      (clasificacion == 3 && bndCountry == true)
                    "
                  >
                    <p class="font-weight-bolder">Internacional</p>
                  </template>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="font-weight-light">Descripción:</p>

                  <template v-if="clasificacion == 1 && bndCountry == false">
                    <p class="font-weight-bolder">Paquete Dictamen</p>
                  </template>
                  <template
                    v-if="
                      (clasificacion == 2 && bndCountry == false) ||
                      (clasificacion == 3 && bndCountry == false)
                    "
                  >
                    <p class="font-weight-bolder">Paquete Registro Nacional</p>
                  </template>

                  <template
                    v-if="
                      (clasificacion == 2 && bndCountry == true) ||
                      (clasificacion == 3 && bndCountry == true)
                    "
                  >
                    <p class="font-weight-bolder">
                      Paquete Registro Internacional
                    </p>
                  </template>
                </div>

                <div class="d-flex justify-content-between">
                  <p class="font-weight-light description">Total:</p>
                  <p class="font-weight-bolder"></p>

                  <template v-if="clasificacion == 1 && bndCountry == false">
                    <p class="font-weight-bolder">
                      ${{ (totalPago = this.precioDictamen) }}MXN
                    </p>
                  </template>

                  <template
                    v-if="
                      (clasificacion == 2 && bndCountry == false) ||
                      (clasificacion == 3 && bndCountry == false)
                    "
                  >
                    <p class="font-weight-bolder">
                      ${{ (totalPago = this.precioRegNac) }}MXN
                    </p>
                  </template>

                  <template
                    v-if="
                      (clasificacion == 2 && bndCountry == true) ||
                      (clasificacion == 3 && bndCountry == true)
                    "
                  >
                    <template v-if="selectedCountry == 1">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4805.31)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 2">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4084.51)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 3">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5744.52)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 4">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 37350.33)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 5">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 13105.38)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 6">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5810.05)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 7">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4259.25)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 8">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4586.88)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 9">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4281.09)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 10">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 7128.76)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 11">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 3030.27)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 12">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5079.52)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 13">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5428.32)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 14">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5057.72)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 15">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 7760.98)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 16">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 7324.97)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 17">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 6583.75)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 18">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 10028.23)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 19">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 3291.88)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 20">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2528.86)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 21">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5297.52)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 22">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2114.65)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 23">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 6845.36)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 24">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 8262.39)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 25">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2768.66)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 26">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2703.26)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 27">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2725.06)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 28">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5602.73)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 29">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4883.31)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 30">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 9635.82)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 31">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2071.05)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 32">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 8218.79)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 33">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 6801.76)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 34">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 7412.17)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 35">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 5646.33
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 36">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5450.13)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 37">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2877.67)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 38">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4708.91)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 39">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 1373.43
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 40">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 10551.44)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 41">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 15347.55)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 42">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4948.71)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 43">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 4033.09
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 44">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4883.31)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 45">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2681.46)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 46">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5493.73)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 47">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 4272.9
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 48">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5079.52)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 49">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 6496.55)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 50">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 5275.72)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 51">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 4926.91
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 52">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 9810.23)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 53">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 9112.61)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 54">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 6976.16)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 55">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4163.9)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 56">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4512.7)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 57">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 4970.51)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 58">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 1286.23)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 59">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 9352.42)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 60">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 19555.05)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 61">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            pesos + 14263.02 + 22410.92
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 62">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 3095.67)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 63">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 1417.03)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                    <template v-if="selectedCountry == 64">
                      <p class="price font-weight-bolder">
                        $
                        {{
                          new Intl.NumberFormat("en-US").format(
                            (totalPago = pesos + 14263.02 + 2114.65)
                          )
                        }}
                        MXN
                      </p>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-if="habilitado">
      <loader object="#ffffff" color1="#ffffff" color2="#17fd3d" size="6" speed="2" bg="#343a40" objectbg="#999793"
              opacity="80" disableScrolling="false" name="dots"></loader>
    </template>
  </b-container>
</template>
<script>
import ProductosyServicios from "../components/ProductosyServicios.vue";
import { contadorUsuarios } from "../assets/js/ContadorUsuarios.js";


async function getPaquetesFromBD() {
  var axios = require("axios");

  var config = {
    method: "get",
    url: process.env.VUE_APP_PQTS_URL,
    headers: {}
  };

  return await axios(config)
    .then(function(response) {
      console.log(response.data);
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
    });
}

let ultimosPrecios = {
  p1: 0,
  p2: 0,
  p3: 0
};

async function getPrecios() {
  let data = await getPaquetesFromBD();


  if (data && data.length == 3) {

    let precios = {
      p1: data[0].precio,
      p2: data[1].precio,
      p3: data[2].precio
    };

    ultimosPrecios["p3"] = data[0].precio;
    ultimosPrecios["p3"] = data[1].precio;
    ultimosPrecios["p3"] = data[2].precio;

    console.log("precios:", precios);
    return precios;
  }

  console.log("Enviando ultimos precios");
  return ultimosPrecios;

}


export default {
  name: "DictamenGratuito",

  data: function() {

    let newVar = {
      apiUrl: "https://www.datidev.com:8080/QRM-API",
      //apiUrl: 'http://localhost:8091',
      items: [],
      countryList: [],
      listaPais: [],
      listaEstados: [],
      template: ProductosyServicios,
      attribu: {
        placeholder: "Seleccione por favor"
      },
      bndCountry: false,
      generalProducts: [
        {
          value: null,
          text: "Por favor seleccione una opción",
          disabled: true
        },
        { value: "1", text: "Dictamen de Viabilidad" },
        { value: "2", text: "Registro de Marca" },
        { value: "3", text: "Registro de Aviso Comercial (slogan)" }
      ],
      bndProducto: false,
      subProducts: [
        {
          idProduct: "1",
          options: [
            { value: "1", text: "Marca" },
            { value: "2", text: "Aviso Comercial (slogan)" }
          ]
        },
        {
          idProduct: "2",
          options: [
            { value: "3", text: "Nacional" },
            { value: "4", text: "Internacional" }
          ]
        },
        {
          idProduct: "3",
          options: [
            { value: "5", text: "Nacional" },
            { value: "6", text: "Internacional" }
          ]
        }
      ],
      isSaving: false,
      alreadyInUse: false,
      isPaid: false,
      maxLPhone: 10,
      maxLCard: 16,
      minLCard: 15,
      maxLMonth: 2,
      minLMonth: 1,
      maxLYear: 4,
      minLYear: 2,
      maxLCVC: 4,
      minLCVC: 3,
      maxCodigo: 5,
      currentStep: 1,
      nombreSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "SEÑOR TEST" : "",
      telefonoSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "5512312300" : "",
      celularSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "2212312300" : "",
      correoSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "viccel2007@gmail.com" : "",
      marcaSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "MARCA TEST" : "",
      sologanSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "ESTAMOS DE PROMO EN DEV, APROVECHE" : "",
      logotipo: "",
      marcaDosSolicitante: "",
      dateSolicitante: "",
      productoSolicitante: "",
      tipo_impresion: "",
      observaciones: "",
      checked: false,
      aceptaTerminos: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? true : false,
      aceptaCondiciones: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? true : false,
      erroresTerminos: "",

      precioDictamen: 0,
      precioRegNac: 0,
      precioRegInter: 0,
      totalPago: "",
      errors: [],
      pagoNetPay: "",
      idTransaccion: "",
      redirectUrl: "",
      respuesta: "",
      mensajeError: "",
      habilitado: "",
      paisSolicitante: "",
      ciudaduSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "CUERNAVACA" : "",
      codigoPostal: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "62000" : "",
      estadoSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? 28 : 0,
      calleASolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "CALLE A" : "",
      calleBSolicitante: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "CALLE B" : "",
      tokenApiCountry: "",
      card: {
        number: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "4000000000000002" : "",
        name: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "VICTOR" : "",
        lastName: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "RODRIGUEZ" : "",
        exp_year: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "2024" : "",
        exp_month: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "11" : "",
        cvc: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? "123" : ""
      },
      maxDate: "",
      isCompleted: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? true : false,
      tokenConekta: {},
      clasificacion: null,
      selectedProduct: null,
      //imsc
      selectProductLabel: "null",
      selectProductPrice: null,
      //imsc
      selectedCountry: (process.env.VUE_APP_NODE_ENV.includes("dev")) ? {
        "nombre": "México",
        "name": "Mexico",
        "nom": "Mexique",
        "iso2": "MX",
        "iso3": "MEX",
        "phone_code": 52
      } : [],
      value: [],
      invalidPhone: true,
      options: [
        { text: "He leído y acepto términos y condiciones", value: "first" },
        { text: "He leído y acepto política de privacidad", value: "second" }
      ],
      clasifications: [
        { value: null, text: "Por favor seleccione opción", disabled: true },
        {
          value: "Dictamen de viabilidad de registro de marca",
          text: "Dictamen de viabilidad de registro de marca."
        },
        {
          value: "Dictamen de viabilidad de aviso comercial(slogan)",
          text: "Dictamen de viabilidad de aviso comercial(slogan)."
        },
        {
          value: "Registro de marca comercial",
          text: "Registro de marca comercial"
        },
        {
          value: "Registro de aviso comercial(slogan)",
          text: "Registro de aviso comercial(slogan)."
        },
        {
          value: "Registro de marca internacional",
          text: "Registro de marca internacional."
        }
      ],
      tipoImpresion: [
        {
          value: null,
          text: "Por favor seleccione una opción",
          disabled: true
        },
        { value: "1", text: "Color" },
        { value: "2", text: "Blanco y negro" }
      ],
      products: [],
      id_solicitud: "",
      regEmail:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhoneNumber: /^[0-9]{5}[0-9]{5}$/,
      pesos: 20.66 * this.precioRegInter
    };
    console.log("newVar tipo:", typeof (newVar));
    console.log(newVar);
    return newVar;


  },

  watch: {
    clasificacion(val) {
      this.products = [];
      let subOptions = this.subProducts.filter((x) => x.idProduct === val);
      this.products = subOptions[0].options;
    }
  },
  // ini imsc

  setInfo() {
    this.selectProductLabel = "Dictamen Viabilidad";

    if (this.clasificacion == "1") {
      this.selectProductLabel = "Dictamen Viabilidad";
    }
    if (this.clasificacion == "2") {
      this.selectProductLabel = "Registro de Marca";
    }
    if (this.clasificacion == "3") {
      this.selectProductLabel = "Registro otro";
    }
  },
  // fin imsc
  async mounted() {
    const scriptNetPay = document.createElement("script");
    scriptNetPay.src = "https://docs.netpay.mx/cdn/v1.3/netpay.min.js";
    scriptNetPay.async = true;
    document.body.appendChild(scriptNetPay);

    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = true;
    document.body.appendChild(script);

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    this.maxDate = maxDate;
    // this.currentStep = 3
    this.getProducts();
    this.getCountries();
    this.getPais();
  },

  computed: {

    tipoEstado: function() {
      console.log("tipoEstado:", " -- -- ");
      return this.habilitado === "habilitado";
    },

    state() {
      return this.value.length === 2;
    },
    textHead() {
      return this.currentStep === 4
        ? `Formato de Solicitud Paso 3 de 3`
        : `Formato de Solicitud Paso ${this.currentStep} de 3`;
    },
    textTitle() {
      let x =
        this.currentStep === 1
          ? "Datos del Solicitante"
          : this.currentStep === 2
            ? "Paquete a elegir"
            : this.currentStep === 3
              ? "Pago"
              : "Éxito";
      return x;
    }

  },

  created() {
    this.resolvePrecios();
  },

  methods: {
    resolvePrecios() {

      getPrecios().then(precios => {
        this.precioDictamen = precios["p1"];
        this.precioRegNac = precios["p2"];
        this.precioRegInter = precios["p3"];
        this.pesos = 20.66 * this.precioRegInter;
      });
    },

    resetSteps() {
      this.currentStep = 1;
      this.isPaid = false;
      this.nombreSolicitante = "";
      this.telefonoSolicitante = "";
      this.celularSolicitante = "";
      this.correoSolicitante = "";
      this.marcaSolicitante = "";
      this.sologanSolicitante = "";
      this.logotipo = "";
      this.marcaDosSolicitante = "";
      this.dateSolicitante = "";
      this.productoSolicitante = "";
      this.observaciones = "";
      this.tipo_impresion = "";
      this.alreadyInUse = false;
      this.totalPago = "";
      this.ciudadSolicitante = "";
      this.codigoPostal = "";
      this.paisSolicitante = "";
      this.estadoSolicitante = "";
      this.calleASolicitante = "";
      this.calleBSolicitante = "";
    },
    getLabel(item) {
      return item.name;
    },
    getProducts() {
      fetch("pys.json")
        .then((response) => response.json())
        .then(
          (data) =>
            (this.items = data.sort((a, b) => {
              let order = a.name.toLowerCase(),
                fb = b.name.toLowerCase();
              if (order < fb) {
                return -1;
              }
              if (order > fb) {
                return 1;
              }
              return 0;
            }))
        );
    },

    checkProduct(e) {
      const type = this.subProducts.filter(
        (z) => z.idProduct === this.clasificacion
      );
      const subtype = type[0].options.filter((x) => x.value === e);

      if (subtype[0].text === "Internacional") {
        this.bndCountry = true;
      } else {
        this.bndCountry = false;
      }

      if (subtype[0].text === "Marca") {
        this.bndProducto = true;
      } else {
        this.bndProducto = false;
      }
    },

    getCountries() {

      fetch("countries.json")
        .then((response) => response.json())
        .then((data) => {
          this.countryList = data;
        });
    },
    getPais() {
      let axiosConfig = {
        headers: {
          "Accept": "application/json",
          "api-token": "5d15h7WJVuCrcjr7jxQnusy0XqVU9YJmUMqoyWWiB6mVDdoIz5Wg22-_5vBoU1JURLE",
          "user-email": "lifynneyepxjediuxm@nthrl.com"
        }
      };
      try {
        const solUrlProd = "https://www.universal-tutorial.com/api/getaccesstoken";

        this.axios.get(solUrlProd, axiosConfig).then(
          (response) => {
            this.addPais(response.data);
          },
          (err) => {
            console.log("Error api en la country: " + err);
          }
        );
      } catch (e) {
        console.log("Error try-catch en country " + e);
      }
      /*fetch('paises.json')
        .then((response) => response.json())
        .then((json) => {
          for(let i =0;i<248;i++){
            var colocarPais={
              value:json[i].iso2,
              text:json[i].nombre
            }
            this.listaPais.push(colocarPais);
            
          }
        })*/
    },
    addPais(token) {
      var getToken = token.auth_token;
      this.tokenApiCountry = getToken;
      let axiosConfig = {
        headers: {
          "Authorization": "Bearer " + getToken,
          "Accept": "application/json"
        }
      };
      try {
        const solUrlProd = "https://www.universal-tutorial.com/api/countries/";
        this.axios.get(solUrlProd, axiosConfig).then(
          (response) => {
            for (let i = 0; i < 244; i++) {
              var colocarPais = {
                value: response.data[i].country_short_name,
                text: response.data[i].country_name
              };
              this.listaPais.push(colocarPais);

            }
          },
          (err) => {
            console.log("Error country " + err);
          }
        );
      } catch (e) {
        console.log("Error try-catch en api-country " + e);
      }

    },
    searchCountry() {
      this.listaEstados = [];
      var combo = document.getElementById("optionSearch");
      var pais = combo.options[combo.selectedIndex].text;
      let axiosConfig = {
        headers: {
          "Authorization": "Bearer " + this.tokenApiCountry,
          "Accept": "application/json"
        }
      };
      try {
        const solUrlProd = "https://www.universal-tutorial.com/api/states/" + pais;
        this.axios.get(solUrlProd, axiosConfig).then(
          (response) => {
            var limite = response.data.length - 1;
            if (limite <= 0) {
              var colocarEstadoVacio = {
                value: pais,
                text: pais
              };
              this.listaEstados.push(colocarEstadoVacio);
            } else {
              for (let i = 0; i < limite; i++) {
                var colocarEstado = {
                  value: response.data[i].state_name,
                  text: response.data[i].state_name
                };
                this.listaEstados.push(colocarEstado);
              }
            }
          },
          (err) => {
            console.log("Error search country " + err);
          }
        );
      } catch (e) {
        console.log("Error try-catch en search-country " + e);
      }

    },

    isEmailValid() {
      return this.correoSolicitante === ""
        ? ""
        : this.regEmail.test(this.correoSolicitante);
    },

    isPhoneValid() {
      return this.telefonoSolicitante === ""
        ? ""
        : this.regPhoneNumber.test(this.telefonoSolicitante);
    },

    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    nextStep() {
      if (this.currentStep <= 3) {
        if (this.validateFields(this.currentStep)) {
          if (this.currentStep !== 3) {
            this.currentStep++;
            contadorUsuarios.actualizarDatos(
              "SOLICITUD PASO " + this.currentStep
            );
          }
        }
        if (this.currentStep === 3 && this.errors.length <= 0) {
          //this.newMarkOrder()
        }
      }
    },

    validateFields(step) {
      this.errors = [];

      switch (step) {
        case 1:
          if (!this.nombreSolicitante) {
            this.errors.push("El campo nombre debe contener información válida");
          } else {
            let cadena = this.nombreSolicitante.split(" ");
            if (cadena.length > 1) {
              for (let i = 0; i < cadena.length; i++) {
                if (cadena[i] != "") {
                  if (cadena[i].length < 3) {
                    this.errors.push(
                      "Por favor coloca tu nombre completo correctamente"
                    );
                    break;
                  }
                }
              }
            } else {
              this.errors.push("Por favor coloca tu nombre completo");
            }
          }

          if (this.telefonoSolicitante === "" && this.isPhoneValid()) {
            this.errors.push("El campo teléfono es requerido.");
          }

          if (!this.correoSolicitante === "" && this.isEmailValid()) {
            this.errors.push(
              "El campo correo electrónico debe contener información válida."
            );
          }
          if (!this.isPhoneValid()) {
            this.errors.push(
              "El campo teléfono debe contener información válida y solo contener 10 dígitos."
            );
          }
          if (!this.isEmailValid()) {
            this.errors.push(
              "El campo correo electrónico debe contener información válida."
            );
          }
          if (this.paisSolicitante === "") {
            this.errors.push(
              "Por favor indica el nombre de tu país"
            );
          }
          if (this.ciudadSolicitante === "") {
            this.errors.push(
              "Por favor indica el nombre de tu ciudad"
            );
          }
          if (this.codigoPostal === "") {
            this.errors.push(
              "Por favor indica tu código postal correctamente"
            );
          }
          if (this.estadoSolicitante === "") {
            this.errors.push(
              "Por favor indica tu estado"
            );
          }
          if (this.calleASolicitante === "") {
            this.errors.push(
              "Por favor coloca la calle 1"
            );
          }
          if (this.calleBSolicitante === "") {
            this.errors.push(
              "Por favor coloca la calle 2"
            );
          }
          break;
        case 2:
          if (!this.marcaSolicitante) {
            this.errors.push(
              "El campo denominación debe contener información válida"
            );
          }

          if (!this.productoSolicitante) {
            this.errors.push(
              "El campo productos y/o servicios de la marca debe contener información válida"
            );
          }

          if (this.bndCountry) {
            if (this.tipo_impresion == "") {
              this.errors.push("Por favor selecciona el tipo de impresión");
            } /*else {
              if (this.tipo_impresion == '1') {
                this.tipo_impresion = 'Color'
              } else if (this.tipo_impresion == '2') {
                this.tipo_impresion = 'Blanco y Negro'
              }
            }*/
          }
          break;
        case 3:
          break;
      }

      return this.errors.length <= 0;
    },

    handleImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
        const selImage = e.target.files[0];
        this.createBase64(selImage);
      } else {
        this.errors.push("El formato de tu logotipo es incorrecto");
        setTimeout(() => {
          this.errors = [];
        }, 7000);
      }
    },

    createBase64(fileObj) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logotipo = e.target.result;
      };
      reader.readAsDataURL(fileObj);

    },

    //async newMarkOrder() {
    newMarkOrder() {
      this.isSaving = true;
      let $this = this;
      let data = {
        nombreSolicitante: this.nombreSolicitante,
        telefono: this.telefonoSolicitante,
        celular: this.celularSolicitante,
        mail: this.correoSolicitante,
        nombreMarca: this.marcaSolicitante,
        slogan: this.sologanSolicitante,
        anexo: btoa(this.logotipo),
        fecha: this.dateSolicitante,
        productosServicios: this.productoSolicitante.name,
        observaciones: this.observaciones,
        tipo_impresion: this.tipo_impresion,

        clasificacion:
          this.clasificacion === 1
            ? "Dictamen de viabilidad de marca"
            : this.clasificacion === 2
              ? "Registro de marca nacional"
              : "Registro de aviso comercial",
        // tipoClasificacion: this.selectedProduct,
        numRegistroMarcaSimilar: ""
      };

      console.log(data);

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Requested-With": "XMLHttpRequest"
        }
      };
      try {
        const solUrlProd = this.apiUrl + "/solicitud/nuevaSolicitud";
        console.log("conectando a " + solUrlProd);
        /*await*/
        this.axios.post(solUrlProd, data, axiosConfig).then(
          (response) => {
            const ide = response.data.id;
            console.log(ide);
            if (ide) {
              this.id_solicitud = ide;
              if ($this.currentStep < 3) {
                $this.currentStep++;
              }
            } else {
              $this.errors.push("La solicitud no pudo ser guardada");
            }
            $this.isSaving = false;
          },
          (err) => {
            console.log("Error api en la solicitud: " + err);
          }
        );

        this.axios.post(process.env.VUE_APP_CrSolicitud, data, axiosConfig)
          .then((res) => {
            if (res) {
              console.log('test php api res:', res);
            }
        }).catch(err =>{
          console.log(`error: ${err}`);
        });

      } catch (e) {
        console.log("Error try-catch en solicitud " + e);
      }
    },

    obtenerToken() {
      this.habilitado = "";
      this.habilitado = "habilitado";
      if (this.card.lastName == "") {
        this.habilitado = "";
        this.errors.push("El apellido del tarjetahabiente es inválido.");
        setTimeout(() => {
          this.errors = [];
        }, 4000);
      } else {
        this.tokenCard();
      }
    },

    tokenCard() {

      // Obtener los precios de los 3 paquetes:


      if (this.clasificacion == 1 && this.bndCountry == false) {
        this.totalPago = this.precioDictamen;
      } else if (
        (this.clasificacion == 2 && this.bndCountry == false) ||
        (this.clasificacion == 3 && this.bndCountry == false)
      ) {
        this.totalPago = this.precioRegNac;
        console.log("total a pagarsh:", this.totalPago);
      }
      if (this.card.exp_month.length < 2) {
        this.card.exp_month = "0" + this.card.exp_month;
      }
      let cardInformation = {
        cardNumber: this.card.number,
        expMonth: this.card.exp_month,
        expYear: this.card.exp_year,
        cvv2: this.card.cvc
      };
      console.log(cardInformation);
      window.NetPay.setApiKey(process.env.VUE_APP_NETPAY_PU_KEY);

      let sandBoxMode = process.env.VUE_APP_NODE_ENV.includes("develop") ? true : false;
      console.log("VUE_APP_NODE_ENV:", process.env.VUE_APP_NODE_ENV);
      console.log("sandbox mode:", sandBoxMode);
      window.NetPay.setSandboxMode(sandBoxMode);
      var validateNumber = window.NetPay.card.validateNumber(
        cardInformation.cardNumber
      );
      var validateExpiry = window.NetPay.card.validateExpiry(
        cardInformation.expMonth,
        cardInformation.expYear
      );
      var validateCVV = window.NetPay.card.validateCVV(
        cardInformation.cvv2,
        cardInformation.cardNumber
      );
      var validateNumberLength = window.NetPay.card.validateNumberLength(
        cardInformation.cardNumber
      );
      if (
        validateNumber == true &&
        validateExpiry == true &&
        validateCVV == true &&
        validateNumberLength == true
      ) {

        this.inicializarNetPay(cardInformation);
      } else {
        this.habilitado = "";
        this.errors.push("Por favor verifica los datos de tu tarjeta");
        setTimeout(() => {
          this.errors = [];
        }, 7000);
      }
    },
    /*
    inicializarNetPay(cardInformation) {
      var cardFirstName = this.card.name;
      var mail = this.correoSolicitante;
      var phone = this.telefonoSolicitante;
      var cardLastName = this.card.lastName;
      var total = this.totalPago;
      var codigoUnico = new Date();
      let tis = this;
      console.log(phone + "" + mail);
      window.NetPay.token.create(cardInformation, success, error);
      console.log("EL pago es de " + total);
      console.log("EL pago es total final " + total);

      function success(e) {
        var token = JSON.parse(e.message.data).token;
        console.log("valores token");
        console.log(token);

        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: process.env.VUE_APP_NETPAY_PR_KEY,
            "Content-Type": "application/json"
          },

          body: JSON.stringify({

            amount: total,
            description: "default",
            paymentMethod: "card",
            currency: "MXN",
            billing: {
              firstName: cardFirstName,
              lastName: cardLastName,
              email: mail,
              phone: phone,
              merchantReferenceCode: codigoUnico.getTime(),
              address: {
                city: tis.ciudadSolicitante,
                country: tis.paisSolicitante,
                postalCode: tis.codigoPostal,
                state: tis.estadoSolicitante,
                street1: tis.calleASolicitante,
                street2: tis.calleBSolicitante
              }
            },
            redirect3dsUri: "https://www.quieroregistrarmimarca.com/Review.html",
            saveCard: false,
            // actualizar el toquen: tokenizar_tarjeta
            source: token
          })
        };
        fetch(
          process.env.VUE_APP_NETPAY_URL_BASE + "/v3/charges",
          options
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("--imc--");
            console.log(response);
            tis.respuesta = response;
            console.log("Response" + response.status);
            console.log("idTransaccion" + response.transactionTokenId);
            tis.idTransaccion = response.transactionTokenId;
            tis.redirectUrl = response.returnUrl;
            tis.mensajeError = response.error;
            tis.detalle();
          })
          .catch((err) => {
            console.log(err);
            tis.habilitado = "";
          });
        console.log("---imsc---");
      }

      function error(e) {
        tis.habilitado = "";
        console.log("algo salio mal al realizar el token ");
        console.log(e);
        tis.errors.push("Lo sentimos, la transacción no se pudo completar");
        setTimeout(() => {
          tis.errors = [];
        }, 7000);
      }
    },
    detalle() {
      var tis = this;
      console.log("Id Transaccion =>" + this.idTransaccion);
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
          process.env.VUE_APP_NETPAY_PR_KEY,
          "Content-Type": "application/json"
        }
      };
      fetch(
        process.env.VUE_APP_NETPAY_URL_BASE + "/v3/transactions/" +
        this.idTransaccion,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          var res = response.status;
          if (res == "DONE" || res == "done") {
            this.newMarkOrder();
            this.createPayment();
            tis.habilitado = "";
          } else if (res == "REVIEW" || res == "review") {
            console.log("Respuesta de vuelta");
            console.log(tis.respuesta);
            var win = window.open(tis.redirectUrl, "windowName");
            try {
              win.focus();
            } catch (e) {
              tis.errors.push("El bloqueador de ventanas emergentes esta habilitado, por favor deshabilite e intente nuevamente");
              tis.habilitado = "";
              setTimeout(() => {
                tis.errors = [];
              }, 10000);
            }
            var timer = setInterval(function() {
              if (win.closed) {
                clearInterval(timer);
                let token = this.respuesta.transactionTokenId;
                tis.habilitado = "";
                if (token != "" || token.length != 0) {
                  const options = {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      Authorization:
                      process.env.VUE_APP_NETPAY_PR_KEY,
                      "Content-Type": "application/json"
                    }
                  };

                  fetch(
                    process.env.VUE_APP_NETPAY_URL_BASE + "/v3/transactions/" +
                    token,
                    options
                  )
                    .then((response) => response.json())
                    .then((response) => {
                      console.log("repuesta semifinal");
                      console.log(response);
                      let tipoRespuesta = response.status;
                      if (
                        tipoRespuesta == "CHARGEABLE" ||
                        tipoRespuesta == "chargeable"
                      ) {
                        const opt = {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            Authorization:
                            process.env.VUE_APP_NETPAY_PR_KEY,
                            "Content-Type": "application/json"
                          }
                        };
                        fetch(
                          process.env.VUE_APP_NETPAY_URL_BASE + "/v3/charges/" +
                          response.transactionTokenId +
                          "/confirm",
                          opt
                        )
                          .then((response) => response.json())
                          .then((response) => {
                            console.log("Respuesta final");
                            console.log(response);
                            tis.newMarkOrder();
                            tis.createPayment();
                          });
                      } else if (
                        tipoRespuesta == "FAILED" ||
                        tipoRespuesta == "failed"
                      ) {
                        tis.errors.push("Por favor verifica tu código");
                        setTimeout(() => {
                          tis.errors = [];
                        }, 10000);
                      } else if (
                        tipoRespuesta == "WAIT_THREEDS" ||
                        tipoRespuesta == "wait_threeds"
                      ) {
                        tis.errors.push("Lo sentimos la transacción no se pudo completar");
                        setTimeout(() => {
                          tis.errors = [];
                        }, 10000);
                      } else {
                        tis.errors.push(
                          "Lo sentimos no se pudo realizar la transacción"
                        );
                        setTimeout(() => {
                          tis.errors = [];
                        }, 10000);
                      }
                    })
                    .catch((err) => {
                      tis.habilitado = "";
                      console.error(err);
                    });
                } else {
                  tis.habilitado = "";
                  tis.errors.push(
                    "Lo sentimos no se pudo realizar la transacción por favor comunicate a hercar@mail.com"
                  );
                  setTimeout(() => {
                    tis.errors = [];
                  }, 10000);
                }
              }
            }, 1000);
          } else {
            tis.habilitado = "";
            if (tis.mensajeError == "") {
              tis.mensajeError == "Lo sentimos ha ocurrido un error";
            }
            tis.errors.push(tis.mensajeError);
            setTimeout(() => {
              tis.errors = [];
            }, 10000);
          }
        })
        .catch((err) => console.error(err));
    },
    createPayment() {
      this.habilitado = "";
      console.log("Pago realizado con éxito");
      let $this = this;
      let data = {
        token_id: this.tokenConekta.id,
        id_solicitud: this.id_solicitud
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Requested-With": "XMLHttpRequest"
        }
      };
      let uri = this.apiUrl + "/pago/ordenarPago";
      this.axios.post(uri, data, axiosConfig).then(
        (response) => {
          if (response.data.respuesta_conekta == "paid") {
            this.isPaid = true;
            this.currentStep = 4;
            this.pagoNetPay = 0;
            contadorUsuarios.realizoPago(1);
          } else {
            $this.errors.push("El pago no pudo ser procesado con éxito.");
          }
        },
        (err) => {
          console.log("Error al realizar el pago: " + err);
          $this.isPaid = true;
          $this.currentStep = 4;
          contadorUsuarios.realizoPago(1);
          this.pagoNetPay = 0;
        }
      );
    },
*/
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    }
  }
};
</script>

<style>
.lay {
  visibility: hidden !important;
}

.card-payment {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.divider-dashed {
  border: none;
  margin-top: 0;
  border-bottom: 2px dashed rgba(84, 84, 84, 0.4);
}

.item-cart {
  border-top: 1px solid rgba(84, 84, 84, 0.2);
  border-bottom: 1px solid rgba(84, 84, 84, 0.2);
  height: auto;
}

.qty {
  color: #2c3e50;
}

.price {
  color: #2c3e50;
  font-size: 20px;
}

.description {
  font-size: 15px;
}

.card-header {
  background: #d4b848 !important;
  color: white;
  font-weight: bolder !important;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  max-width: 550px !important;
  word-break: break-word !important;
  transition: width 0.5s;
}

.c-header {
  font-weight: bolder !important;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  max-width: 550px !important;
  word-break: break-word !important;
  transition: width 0.5s;
}

.card-title {
  font-size: 32px !important;
  font-weight: bolder !important;
}

.card-subtitle {
  letter-spacing: 1px !important;
  font-size: 18.5px !important;
  margin-top: 4px !important;
}

.card-subtitle:before {
  content: 'Registra tu marca o aviso comercial (Slogan).';
}

.card-subtitle:after {
  content: ' Llena el siguiente formulario';
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Buscar' !important;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: black !important;
}

.invalid-feedback {
  color: #000000 !important;
}

.v-autocomplete .v-autocomplete-input-group .v-autocomplete-input {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  box-shadow: none;
  width: 100%;
}

.v-autocomplete
.v-autocomplete-input-group.v-autocomplete-selected
.v-autocomplete-input {
  color: #393b3b;
  background-color: #f2fff2;
}

.v-autocomplete .v-autocomplete-list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 215px;
  overflow-y: auto;
  border-bottom: 1px solid #393b3b;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #393b3b;
  border-left: 1px solid hsl(180, 2%, 23%);
  border-right: 1px solid #393b3b;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:last-child {
  border-bottom: none;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:hover {
  background-color: #eee;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item abbr {
  opacity: 0.8;
  font-size: 0.8em;
  display: block;
  font-family: sans-serif;
}
</style>
