import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import LandingPage from '@/views/LandingPage.vue'
import AvisoPrivacidadPage from '@/views/AvisoPrivacidadPage.vue'
import TerminosCondicionesPage from '@/views/TerminosCondicionesPage'
import SolicitudPage from '@/views/SolicitudPage.vue'
import ConfirmacionSolicitudPage from '@/views/ConfirmacionSolicitudPage.vue'
import Review from '@/views/Review.vue'
import DictamenGratuito from '@/views/DictamenGratuito.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    children: [
      { path: '/', name: 'LandingPage', component: LandingPage },
      { path: 'solicitud', name: 'SolicitudPage', component: SolicitudPage },
      { path: 'Dictamengratuito', name: 'DictamenGratuito', component: DictamenGratuito },
      {
        path: 'confirmacion-solicitud',
        name: 'ConfirmacionSolicitudPage',
        component: ConfirmacionSolicitudPage,
      },
      {
        path: '/terminos-condiciones',
        name: 'TerminosCondicionesPage',
        component: TerminosCondicionesPage,
      },
      {
        path: '/aviso-privacidad',
        name: 'AvisoPrivacidadPage',
        component: AvisoPrivacidadPage,
      },
    ],
  },
  {
    path: '/home-terminos-condiciones',
    name: 'TerminosCondicionesSection',
    component: TerminosCondicionesPage,
  },
  {
    path: '/home-aviso-privacidad',
    name: 'AvisoPrivacidadSection',
    component: AvisoPrivacidadPage,
  },
  { path: '/Review',
    name:'Review',
    component:Review
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})



export default router
