<template>
  <b-div>

  </b-div>
</template>
<script>
export default {
  name: 'Review',
  props: {
    msg: String
  },
  data(){
  
  },
  mounted(){
   this.enviarToken()
  },
  methods:{
    enviarToken(){
     window.close();  
    }

}
}
</script>