<template>
  <div id="marcas">
    <div class="container">
      <h2 class="subtitle">MARCAS QUE HAN CONFIADO EN NOSOTROS</h2>
      <hr class="divider" />
      <carousel
        autoplay
        loop
        :autoplaySpeed="true"
        center
        autoplayHoverPause
        :nav="false"
      >
        <div class="item" v-for="(marca, index) in marcas" :key="index">
          <img :src="getImage(marca.logo)" :alt="marca.nombre" />
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
  name: 'Marcas',
  components: { carousel },
  data: function () {
    return {
      marcas: [
        { nombre: 'Appti', logo: 'appti.png' },
        { nombre: 'Asterizco', logo: 'asterizco.png' },
        { nombre: 'Consultorio Jurídico', logo: 'consultorio-juridico.png' },
        { nombre: 'Fernando Huerta', logo: 'fernando-huerta.png' },
        { nombre: 'Grupo Her Car', logo: 'grupo-her-car.png' },
        { nombre: 'HBK Arquitectura', logo: 'hbk-arquitectura.png' },
        { nombre: 'Mobilistico', logo: 'mobilistico.png' },
        { nombre: 'Movemini', logo: 'movemini.png' },
        { nombre: 'Aerofilms', logo: 'aerofilms.png' },
        { nombre: 'Assae', logo: 'asae.png' },
        { nombre: 'Skkuadra', logo: 'skkuadra.png' },
        { nombre: 'Squenda', logo: 'squenda.png' },
      ],
    }
  },
  methods: {
    getImage(img) {
      return require(`@/assets/img/marcas/${img}`)
    },
  },
}
</script>
