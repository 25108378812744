import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'v-autocomplete/dist/v-autocomplete.css'
import './assets/css/styles.css'
import './assets/css/social-links.css'
//import "./assets/js/functions.js";
import './assets/js/tawt-to.js'
//import "./assets/js/validarinputs.js";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Autocomplete from 'v-autocomplete'
import loader from "vue-ui-preloader";

Vue.use(loader);
Vue.use(VueAxios, axios)
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)
Vue.use(Autocomplete)

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
